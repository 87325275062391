<template>
  <div class="qrcode-container mt-5" :class="{ ptStyle: isPropTrading }">
    <div class="step">
      <span class="title"
        >{{ $t('deposit.qrcode.step1') }} - {{ $t('deposit.qrcode.copyPayment', { payment: name }) }}</span
      >
      <div class="method">
        <div class="method-box first-method">
          <span class="title">{{ $t('deposit.qrcode.method1') }}</span>
          <p class="desc">{{ $t('deposit.qrcode.scanThisWithYourPhon') }}</p>
          <div class="image-container">
            <img src="~@/assets/images/deposit/qrcode-rectangle.png" />
            <vue-qrcode class="qrcode" :value="cryptoAddress"></vue-qrcode>
          </div>
        </div>
        <div class="method-box">
          <span class="title">{{ $t('deposit.qrcode.method2') }}</span>
          <p class="desc">{{ $t('deposit.qrcode.enterTheAddressMaually') }}</p>
          <div class="copy-box">
            <div class="copy-content">{{ cryptoAddress }}</div>
            <el-button class="btn-blue copy-btn" :class="{ copied: isCopied }" @click="copyAddress">
              <p v-if="isCopied">
                <i class="el-icon-circle-check"></i> {{ windowWidth > 600 ? $t('deposit.qrcode.copied') : '' }}
              </p>
              <p v-else>
                <!-- <img src="~@/assets/images/new/icon-copy-document.png" /> -->
                <i class="el-icon-copy-document reverse"></i>
                {{ windowWidth > 600 ? $t('deposit.qrcode.copyAddress') : '' }}
              </p>
            </el-button>
          </div>
          <div class="note">
            <p class="mb-2">{{ $t('deposit.qrcode.note') }}:</p>
            <p class="mb-3" v-if="noteList[name]">
              {{
                $t('deposit.qrcode.usdtNote1', {
                  payment: name,
                  number: noteList[name].number,
                  currency: noteList[name].currency
                })
              }}
            </p>
            <p v-if="name !== 'BTC'">
              {{ $t('deposit.qrcode.usdtNote2', { payment: name }) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="step">
      <span class="title"
        >{{ $t('deposit.qrcode.step2') }} - {{ $t('deposit.qrcode.logInYourWallwet', { payment: name }) }}</span
      >
      <div class="deposit-amout">
        <div class="method-box">
          <p class="mb-2 method-desc">{{ $t('deposit.qrcode.depositAmount') }}</p>
          <div class="copy-box">
            <div class="copy-content">
              <span class="bold">{{ name }} &nbsp;</span>
              <span>{{ actualAmount }}</span>
            </div>
            <el-button class="btn-blue copy-btn" :class="{ copied: isCopiedAmount }" @click="copyAmount">
              <p v-if="isCopiedAmount">
                <i class="el-icon-circle-check"></i> {{ windowWidth > 600 ? $t('deposit.qrcode.copied') : '' }}
              </p>
              <p v-else>
                <!-- <img src="~@/assets/images/new/icon-copy-document.png" /> -->
                <i class="el-icon-copy-document reverse"></i>
                {{ windowWidth > 600 ? $t('deposit.qrcode.copyAmount') : '' }}
              </p>
            </el-button>
          </div>
        </div>
      </div>
      <div class="payment-button-container">
        <el-button class="payment-button btn-blue" @click="clickFunC" type="primary" data-testid="paymentButton">{{
          $t('deposit.qrcode.haveMadeThePayment')
        }}</el-button>
        <div class="button-note">{{ $t('deposit.qrcode.sumbitNote') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
export default {
  name: 'qrcode',
  components: {
    VueQrcode
  },
  props: {
    name: { type: String },
    cryptoAddress: { type: String },
    actualAmount: { type: String },
    isPropTrading: { type: Boolean, default: false }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      isCopied: false,
      isCopiedAmount: false,
      noteList: {
        ETH: {
          number: 0.006,
          currency: 'ETH'
        },
        BTC: {
          number: 0.0003,
          currency: 'BTC'
        },
        'USDT-ERC': {
          number: 1,
          currency: 'USDT'
        },
        'USDT-TRC20': {
          number: 5,
          currency: 'USDT'
        },
        'USDT-BEP20': {
          number: 1,
          currency: 'USDT'
        },
        'USDC-ERC20': {
          number: 1,
          currency: 'USDT'
        },
        'USDT-ERC20': {
          number: 1,
          currency: 'USDT'
        }
      }
    };
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowSize);
  },
  methods: {
    copyAddress() {
      const textarea = document.createElement('textarea');
      textarea.value = this.cryptoAddress;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    },
    copyAmount() {
      const textarea = document.createElement('textarea');
      textarea.value = this.actualAmount;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.isCopiedAmount = true;
      setTimeout(() => {
        this.isCopiedAmount = false;
      }, 2000);
    },
    goToThirdPartyDepositResult() {
      this.$router.push('/thirdPartyDepositResult/crypto');
    },
    goToPropTradingDashboard() {
      this.$router.push({ name: 'propTradingDashboard' });
    },
    clickFunC() {
      this.isPropTrading ? this.goToPropTradingDashboard() : this.goToThirdPartyDepositResult();
    },
    updateWindowSize() {
      this.windowWidth = window.innerWidth;
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateWindowSize);
    }
  }
};
</script>
<style lang="scss" scoped>
.qrcode-container {
  margin: 0 auto;
  padding: 2rem;
  border-radius: 8px;
  padding: 20px;
  .step {
    margin-bottom: 20px;
    .title {
      font-weight: bold;
      font-size: 18px;
      color: $blue;
      text-transform: uppercase;
    }
    .deposit-amout {
      margin-top: 2rem;
    }
  }
  .method {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
  }
  .method-box {
    border-radius: 8px;
    padding: 20px;
    border: 1px solid;
    border-color: $blue;
    overflow: hidden;
    &.first-method {
      text-align: center;
      padding: 20px 10px;
      .desc {
        max-width: 60%;
        margin: 1rem auto;
      }
    }
    &:nth-child(2) {
      flex: 2;
      @include rtl-sass-value(text-align, left, right);
      @include rtl-sass-value(margin-left, 2rem, 0);
      @include rtl-sass-prop-dual(margin-left, 2rem, margin-right, 2rem);
    }
    .desc {
      margin: 1rem 0;
      font-weight: 500;
    }
    .image-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 80%;
        min-width: 155px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .method-desc {
      font-weight: 500;
    }
  }
  .qr-code {
    margin-bottom: 10px;
  }
  .copy-box {
    display: flex;
    padding: 10px;
    border-radius: 4px;
    background-color: #f6f7f8;
    overflow: hidden;
    .copy-content {
      flex: 1;
      @include rtl-sass-value(text-align, left, right);
      padding: 0.5rem 1rem;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .bold {
        font-weight: bold;
      }
    }
    .copy-btn {
      display: inline-block;
      padding: 10px 20px;
      min-width: 174px;
      min-height: 42px;
      cursor: pointer;
      i {
        font-size: 16px;
      }
      .reverse {
        transform: rotateX(180deg) rotateY(180deg);
      }
      &.copied {
        color: $blue;
        background-color: $white;
        border: 1px solid;
        border-color: $blue;
      }
    }
  }
  .note {
    @include rtl-sass-value(text-align, left, right);
    margin-top: 0.5em;
    p {
      line-height: 1.6;
    }
  }
  .payment-button-container {
    padding: 3rem 0;
    .payment-button {
      padding: 20px 4rem;
      margin-bottom: 1rem;
    }
  }

  &.ptStyle {
    .copy-box {
      .copy-btn {
        background-color: $sky-blue;
        &:hover {
          background-color: #0154c8;
        }
        &.copied {
          color: $sky-blue;
          background-color: $white;
          border: 1px solid;
          border-color: $sky-blue;
        }
      }
    }
    .payment-button-container {
      text-align: center;
      .payment-button {
        background-color: $sky-blue;
        &:hover {
          background-color: #0154c8;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .qrcode-container {
    .step {
      max-width: 80%;
    }
    .method {
      display: block;
      .method-box {
        &:nth-child(2) {
          margin-top: 2rem;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    &.ptStyle {
      .step {
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 600px) {
  .qrcode-container {
    .copy-box {
      .copy-btn {
        min-width: 60px;
      }
    }
  }
}
@media (max-width: 500px) {
  .qrcode-container {
    .step {
      max-width: 50%;
    }
    .payment-button-container {
      .payment-button {
        padding: 20px 2rem;
        white-space: normal;
      }
    }
  }
}
@media (max-width: 400px) {
  .qrcode-container {
    .step {
      max-width: 40%;
    }
    .payment-button-container {
      .payment-button {
        padding: 20px 1rem;
      }
    }
  }
}
</style>
